import React from 'react'

import Layout from '../components/layout'
import SEO from '../components/seo'

const OverPage = () => (
  <Layout>
    <SEO
      title="Over ons"
      keywords={[
        `Yves De Vrient`,
        `Loodgieter Yves De Vrient`,
        `De Vrient`,
        `Vrient`,
        `Loodgieter De Vrient`,
        `loodgieter`,
        `loodgieter Kruisem`,
        `loodgieter Zingem`,
        `Loodgieter Oudenaarde`,
        `over ons`,
        `over`,
        `Frans De Vrient`,
      ]}
    />
    <h1>Over ons</h1>
    <p>
      In 1970 starte vader Frans De Vrient een éénmanszaak voor het
      loodgieterswerk. De activiteiten waren: sanitaire installaties, platte
      daken, lood- en zinkwerken en koperwerken. Na mijn scholing sanitair -
      centrale verwarming, klimatisatie in 1991 ben ik, Yves voltijds bij mijn
      vader aan de slag gegaan. Enkele jaren later heeft mijn broer Nico ons
      vervoegd.
      <br />
      <br />
      In 1994 heb ik beslist om op eigen benen te staan. Terwijl mijn vader nog
      vooral platte daken en het lood- en zinkwerken en koperwerk verder deed
      met mijn broer Nico, splitste ik mij toe op sanitaire werken en centrale
      verwarming. Na het pensioen van mijn vader heeft Nico de dakwerken verder
      gezet op zelfstandige basis.
      <br />
      <br />
      Samen met mijn vrouw en 2 werknemers kan u bij ons terecht voor uw
      sanitair, centrale verwarming en hernieuwbare energie.
    </p>
  </Layout>
)

export default OverPage
